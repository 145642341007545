import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconProp } from "@fortawesome/fontawesome-svg-core";

import common from "./barInput.module.scss";

interface BarProps {
  type?: string;

  maxLength?: number;
  placeholder: string;
  cb: any;
  value: string;
  icon: IconProp;
  autoComplete?: string;
}

export default function BarInput(props: BarProps) {
  const { type, maxLength, placeholder, cb, value, icon, autoComplete } = props;

  return (
    <div className={common.barInput}>
      <div className={common.iconWrap}>
        <FontAwesomeIcon icon={icon} size='1x' />
      </div>
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={cb}
        maxLength={maxLength}
        autoComplete={autoComplete}
      />
    </div>
  );
}
