import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faEnvelope, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import BarInput from "./barInput";
import { retrieveLocally, storeLocally } from "../utils";

interface Response {
  token?: string;
  failure?: string;
}

const SERVER_URL = "https://login-rutn6z4fsa-uc.a.run.app";
const DOCS_URL = `https://docs.voltera.io/partner-hub`;

function betterErrors(message: string) {
  switch (message) {
    case "Firebase: Error (auth/user-not-found).":
      return "No account associated this email.";
    case "Firebase: Error (auth/wrong-password).":
      return "Password is is incorrect.";
    default:
      return message;
  }
}

export default function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [failure, setFailure] = useState<string | null>("");
  const [location, setLocation] = useState("");
  const [checking, setChecking] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const locationParams = url.searchParams.get("location");
    setLocation(locationParams ?? "");
  }, []);

  useEffect(() => {
    const token = retrieveLocally();
    if (token) {
      const redirectURL = `${DOCS_URL}/${location}?jwt_token=${token}`;
      window.open(redirectURL, "_self");
    }
  }, [location]);

  async function postData() {
    try {
      setChecking(true);
      setFailure(null);
      const response = await axios.post(SERVER_URL, { email, password });
      const result: Response = response.data;
      if (result.token) {
        storeLocally(result.token);
        const redirectURL = `${DOCS_URL}/${location}?jwt_token=${result.token}`;
        window.open(redirectURL, "_self");
      } else if (result.failure) {
        setFailure(result.failure);
      } else {
        setFailure("Unknown error");
      }
    } catch (error) {
      setFailure((error as Error).message);
      console.error(error);
    }
    setChecking(false);
  }

  function renderButton() {
    if (checking) {
      return <FontAwesomeIcon icon={faCircleNotch} spin size='2x' style={{ margin: "11px" }} />;
    } else {
      const disable = email.length < 3 || password.length < 3;
      return (
        <button disabled={disable} onClick={postData}>
          Submit
        </button>
      );
    }
  }

  return (
    <form onSubmit={postData}>
      {failure ? <p>Contact Voltera for assistance</p> : null}

      <BarInput
        icon={faEnvelope}
        value={email}
        placeholder='Email address'
        type='email'
        autoComplete='email'
        cb={(e: any) => setEmail(e.target.value)}
      />

      <BarInput
        icon={faKey}
        value={password}
        placeholder='Enter password'
        type='password'
        autoComplete='current-password'
        cb={(e: any) => setPassword(e.target.value)}
      />
      {failure ? <p style={{ color: "pink" }}>{betterErrors(failure)}</p> : null}
      {renderButton()}
    </form>
  );
}
