import "./css/app.scss";
import "./css/fonts.css";
import Login from "./components/login";

// Initialize the FirebaseUI Widget using Firebase.

function App() {
  return (
    <div className='app'>
      <div className='login'>
        <h1>Partner Hub</h1>
        <Login />
      </div>
    </div>
  );
}

export default App;
