interface Payload {
  expiration: number;
  token: string;
}

export function storeLocally(token: string) {
  const payload: Payload = { expiration: new Date().getTime() + 24 * 60 * 60 * 1000, token };
  localStorage.setItem("portal", JSON.stringify(payload));
}

export function retrieveLocally(): string | null {
  const stringPayload = localStorage.getItem("portal");
  if (stringPayload) {
    const payload: Payload = JSON.parse(stringPayload);
    if (new Date().getTime() < payload.expiration) {
      return payload.token;
    }
  }
  return null;
}
